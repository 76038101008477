<template>
	<article class="lp">
		<h1 class="lp__header"><img src="~@/assets/img/lp_autonomicnerves2021/lp_autonomicnerves_title.png" alt="医師監修 自律神経のバランスチェック！ presented by 頭痛ーる"></h1>

		<!-- イントロ -->
		<div class="lp__intro">
			<p class="lp__intro--text">天気の悪い日に体調不良になりがちな人は、自律神経が乱れているのかもしれません。頭痛ーるアプリで確認してみましょう！</p>
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" id="trackingAutonomicNerves2021Btn01_android" class="lp__intro--bnr">
				<img src="~@/assets/img/lp_autonomicnerves2021/lp_autonomicnerves_bnr.png" alt="体調不良になりやすい日を事前にお知らせ！ いますぐダウンロード">
			</a>
			<a v-else href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" id="trackingAutonomicNerves2021Btn01_ios" class="lp__intro--bnr">
				<img src="~@/assets/img/lp_autonomicnerves2021/lp_autonomicnerves_bnr.png" alt="体調不良になりやすい日を事前にお知らせ！ いますぐダウンロード">
			</a>
		</div>
		<!-- /イントロ -->

		<!-- STEP1 -->
		<section class="lp__contents is-step1">
			<h2 class="lp__contents--title"><span>STEP&nbsp;1</span>自律神経を整えよう！</h2>
			<p class="lp__contents--intro">簡単ストレッチ＆呼吸法で<br>自律神経を整えましょう。</p>
			<div class="lp__contents--inner">
				<h3 class="lp__contents--subtitle">タオルで簡単！首のばし</h3>
				<p class="lp__contents--img"><i class="sprite img01" /></p>
				<p class="lp__contents--text">30秒でできる簡単ストレッチ、頭痛や肩こり、ストレートネックの矯正に効果が期待！</p>
				<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 722 } })" id="trackingAutonomicNerves2021Btn02" class="lp__contents--link">詳しいやり方を見る</a>
			</div>
			<div class="lp__contents--inner">
				<h3 class="lp__contents--subtitle">自律神経を整える！12秒呼吸法</h3>
				<p class="lp__contents--img"><i class="sprite img02" title="スゥー 鎖骨が上がる ※お腹は膨らまない" /></p>
				<p class="lp__contents--text">寝ながら簡単にできる呼吸法。ゆがんだ骨格をリセットして、自律神経を整えます。</p>
				<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 725 } })" id="trackingAutonomicNerves2021Btn03" class="lp__contents--link">詳しいやり方を見る</a>
			</div>
		</section>
		<!-- /STEP1 -->

		<!-- STEP2 -->
		<section class="lp__contents is-step2">
			<h2 class="lp__contents--title"><span>STEP&nbsp;2</span>悪習慣を見直そう！</h2>
			<p class="lp__contents--intro">何気なくやっている習慣、<br>実は自律神経を乱す原因かもしれません。</p>
			<div class="lp__contents--inner">
				<h3 class="lp__contents--subtitle">自律神経に負担をかける<br>6つの習慣</h3>
				<div class="lp__contents--box">
					<p class="lp__contents--img"><i class="sprite img03" /></p>
					<ul class="lp__contents--list">
						<li>自己流の筋トレ</li>
						<li>昼夜逆転の生活</li>
						<li>おやつを <br>毎日たべすぎる…など</li>
					</ul>
				</div>
				<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=sixBadHabit` } })" id="trackingAutonomicNerves2021Btn04" class="lp__contents--link">詳しくみる</a>
			</div>
		</section>
		<!-- /STEP2 -->

		<!-- STEP3 -->
		<section class="lp__contents is-step3">
			<h2 class="lp__contents--title"><span>STEP&nbsp;3</span>自律神経のバランスチェック</h2>
			<p class="lp__contents--intro">12の質問に答えて、自律神経のバランスを<br>チェックしてみましょう。</p>
			<div class="lp__contents--inner">
				<h3 class="lp__contents--subtitle"><i class="sprite title01" title="【医師監修】自律神経のバランスチェック" /></h3>
				<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=balancecheckAutonomicNerves` } })" id="trackingAutonomicNerves2021Btn05" class="lp__contents--link">チェックをはじめる</a>
			</div>
		</section>
		<!-- /STEP3 -->

		<!-- アウトロ -->
		<section class="lp__outro">
			<h2 class="lp__outro--title"><i class="sprite title02" title="頭痛ーるで不調になりやすい日を知ろう！" /></h2>
			<p class="lp__outro--text">自律神経が乱れると、気圧や温度の変化で体調不良になる「気象病」の症状も出やすくなります。頭痛ーるは、不調の原因となる「気圧の変化」をお知らせします。</p>
			<div class="growthhack-f__caution">
				<p class="growthhack-f__caution--right">低気圧で体調崩すかも。早めに用事を済ませよう！</p>
				<p class="growthhack-f__caution--left">痛みが出る前に薬を準備しておこう！</p>
			</div>
			<div class="growthhack-f__with-zutool">
				<p class="growthhack-f__with-zutool--title">
					with <span class="is-large">頭痛ーる</span>で<br>気圧と上手に付き合って<br>快適な毎日へ♪
				</p>
				<i class="growthhack-f__with-zutool--img sprite_meteoropathy with-zutool" />
			</div>
			<div class="about-rightnow__login">
				<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button" id="trackingAutonomicNerves2021Btn06_android">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
				<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button" id="trackingAutonomicNerves2021Btn06_ios">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
			</div>
			<div class="lp__back">
				<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="about-rightnow__login--button">TOPにもどる</a>
			</div>
		</section>
		<!-- /アウトロ -->
	</article>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'LpAutonomicNerves2021',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	mounted () {
		// コンテンツの高さをemitする処理（実処理は親で行う）
		// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
		// this.$nextTick(() => {
		// 	this.$emit('contentHeight')
		// })
	},
	beforeDestroy () {
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/variable";

// NOTE: LPページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-9: 0.562rem;
$spacing-12: 0.75rem;
$spacing-14: 0.875rem;
$spacing-18: 1.125rem;
$spacing-22: 1.375rem;
$spacing-24: 1.5rem;
$spacing-25: 1.562rem;
$spacing-30: 1.875rem;
$spacing-32: 2rem;
$spacing-40: 2.5rem;
$spacing-48: 3rem;
$spacing-64: 4rem;
$spacing-72: 4.5rem;
$font-size-15: 0.937rem;
$font-size-22: 1.375rem;
$line-height-133: 1.33;
$line-height-146: 1.46;
$lp-primary-color: #FC7053;
$lp-secondary-color: #48B8DB;

.lp {
	overflow: hidden;
	font-family: $lp-family-primary;
	background-color: $background-primary;
	line-height: $line-height-146;
	color: $text-primary;
	font-size: $font-size-15;

	&__header {
		width: 94.6667%;
		margin: 0 auto;

		img {
			max-width: 100%;
			vertical-align: bottom;
		}
	}

	&__intro {
		margin: $spacing-22 0 0 0;
		padding: 0 $spacing-14;

		&--text { margin: 0; }

		&--bnr {
			display: block;
			margin: $spacing-10 0 0 0;

			img {
				max-width: 100%;
				vertical-align: bottom;
			}
		}
	}

	&__contents {
		padding: 0 $spacing-14;

		&.is-step1 { margin: $spacing-48 0 0 0; }
		&.is-step2 { margin: $spacing-64 0 0 0; }
		&.is-step3 { margin: $spacing-72 0 0 0; }

		&--inner {
			margin: $spacing-18 0 0 0;
			padding: $spacing-24 0;
			border-radius: 10px;
			border: 2px solid $lp-secondary-color;
		}

		&.is-step2 .lp__contents--inner { padding: $spacing-16 0 $spacing-22; }
		&.is-step3 .lp__contents--inner { padding: $spacing-16 0; }

		&--box {
			position: relative;
			margin: $spacing-12 auto $spacing-32;
		}

		&--title {
			position: relative;
			margin: 0;
			padding: $spacing-30 0 $spacing-25;
			font-size: $font-size-24;
			color: $lp-primary-color;
			text-align: center;
			border-top: 4px solid $lp-primary-color;
			border-bottom: 4px solid $lp-primary-color;
			line-height: $line-height-1;
			white-space: nowrap;

			span {
				display: block;
				position: absolute;
				width: 135px;
				padding: $spacing-8;
				top: -18px;
				left: 50%;
				transform: translateX(-50%);
				box-sizing: border-box;
				border-radius: 16px;
				background-color: $lp-primary-color;
				color: $text-seconday;
				font-size: $font-size-18;
			}

			@media (max-width: (370px)) { font-size: $font-size-22; }
		}

		&--intro {
			margin: $spacing-20 0 0 0;
			text-align: center;
		}

		&--subtitle {
			margin: 0;
			text-align: center;
			font-size: $font-size-20;
			line-height: $line-height-1;

			@media (max-width: (360px)) { font-size: $font-size-18; }

			& > i {
				position: relative;
				margin: 0 auto;
				top: 0;
				left: -2px;

				@media (max-width: (374px)) {
					left: 50%;
					transform: scale(.853) translateX(-50%);
					transform-origin: 0 0;
				}
			}
		}

		&.is-step2 .lp__contents--subtitle { line-height: $line-height-146; }

		&--img {
			margin: $spacing-12 auto $spacing-14;

			@media (max-width: (360px)) { margin: $spacing-12 auto 0 !important; }

			& > i {
				margin: 0 auto;

				@media (max-width: (360px)) {
					position: relative;
					left: 50%;
					transform: scale(.853) translateX(-50%);
					transform-origin: 0 0;
				}
			}
		}

		&--inner:nth-of-type(2) .lp__contents--img { margin: $spacing-18 auto $spacing-10; }

		&--text {
			margin: 0;
			padding: 0 $spacing-18 0 $spacing-24;
		}

		&--list {
			position: absolute;
			padding: 0 0 0 $spacing-18;
			margin: 0;
			top: $spacing-16;
			left: $spacing-24;

			& > li {
				list-style: disc;
				font-size: $font-size-18;
				margin: $spacing-9 0 0 0;
				line-height: $line-height-133;

				&:first-of-type { margin: 0; }
				@media (max-width: (360px)) { font-size: $font-size-15; }
			}
		}

		&--link {
			display: block;
			width: 87.464%;
			margin: $spacing-12 auto 0;
			padding: $spacing-12 0;
			background-color: $lp-secondary-color;
			border-radius: 6px;
			text-align: center;
			color: $text-seconday;
			font-size:  $font-size-18;
			text-decoration: none;
			line-height: $line-height-1;
		}

		&.is-step3 .lp__contents--link { margin: $spacing-22 auto 0; }
	}

	&__outro {
		margin: $spacing-40 0 0 0;

		&--title {
			margin: 0;

			& > i {
				margin: 0 auto;

				@media (max-width: (360px)) {
					position: relative;
					left: 50%;
					transform: scale(.853) translateX(-50%);
					transform-origin: 0 0;
				}
			}
		}

		&--text {
			margin: $spacing-10 0 0 0;
			padding: 0 $spacing-14;
			line-height: $line-height;
		}

		.growthhack-f__caution { margin: $spacing-12 0 0 0; }
		.about-rightnow__login { padding-top: 0; }
	}

	&__back {
		margin: $spacing-20 0 0 0;

		.about-rightnow__login--button { font-weight: normal; }
	}
}

// スプライト
.sprite {
	background-image: url(~@/assets/img/lp_autonomicnerves2021/sprite_lp_autonomicnerves.png);
	background-size: (704px / 2) (1105px / 2);
	background-repeat: no-repeat;
	display: block;

	&.img01 {
		width: (618px / 2);
		height: (274px / 2);
		background-position: (-5px / 2) (-5px / 2);
	}

	&.img02 {
		width: (628px / 2);
		height: (300px / 2);
		background-position: (-5px / 2) (-289px / 2);
	}

	&.img03 {
		width: (600px / 2);
		height: (218px / 2);
		background-position: (-5px / 2) (-599px / 2);
	}

	&.title01 {
		width: (694px / 2);
		height: (151px / 2);
		background-position: (-5px / 2) (-827px / 2);
	}

	&.title02 {
		width: (694px / 2);
		height: (112px / 2);
		background-position: (-5px / 2) (-988px / 2);
	}
}

.sprite_meteoropathy {
	background-image: url(~@/assets/img/selfcheck-meteoropathy/sprite_res.png);
	background-size: (704px / 2) (2917px / 2);
	background-repeat: no-repeat;
	display: block;

	&.with-zutool {
		width: (224px / 2);
		height: (266px / 2);
		background-position: (-5px / 2) (-2646px / 2);
	}
}
</style>
